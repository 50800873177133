import { Option, Select } from '@mui/joy';

import risks from "./risks";

// RiskSelector provides a select box to select a given risk category.
// Whenever a new category is selected, onChange is called with the new risk.
function RiskSelector({ selectedRisk, onChange }) {
    // this passes along the new value to the onChange callback fn passed as prop.
    const handleChange = (_, newValue) => {
        onChange(newValue)
    }

    // risks is a map, where we care about the key and value.name.
    // Convert to a list of objects with `value` and `name` keys
    let options = []

    for (const [k, v] of Object.entries(risks)) {
        options.push({
            value: k,
            name: v.name,
        })
    }

    return (
        <Select size="sm" value={selectedRisk} onChange={handleChange}>
            {options.map((e, idx) => {
                return (
                    <Option value={e.value} key={idx}>{e.name}</Option>
                )
            })}
        </Select>
    )
}

export default RiskSelector