import { dsv } from "d3-fetch";

async function parseData(path) {
    let data = await dsv(';', path)

    // apply a bunch of workarounds to the data.
    return data.map((e) => {

        for (const [k, v] of Object.entries(e)) {
            // keep using k2 now.
            let k2 = k.trim()

            // scores are strings with comma.
            // replace ',' with '.' and convert to number.
            if (k2.endsWith(" score")) {
                e[k2] = Number(v.replace(/,/g, "."))
            }

        }

        return e
    })
}

export default parseData
