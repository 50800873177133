import colorScheme from "./colorScheme";

function Legend() {
    return (
        <>
            <defs>
                <linearGradient id="gradient01">
                    <stop offset="0%" stopColor={colorScheme.colorMinimum} />
                    <stop offset="25%" stopColor={colorScheme.colorMediumLow} />
                    <stop offset="50%" stopColor={colorScheme.colorMedium} />
                    <stop offset="75%" stopColor={colorScheme.colorMediumHigh} />
                    <stop offset="100%" stopColor={colorScheme.colorMaximum} />
                </linearGradient>
            </defs>
            <g
                style={{
                    fontSize: '0.7em',
                    fontWeight: 300,
                }}
            >
                <text x={7} y={370}>
                    1
                </text>
                <text x={85} y={370}>
                    5
                </text>
            </g>
            <rect
                x={10}
                y={374}
                style={{
                    fill: 'url(#gradient01)',
                    width: '5rem',
                    height: '0.6rem',
                }}
            >
            </rect>
        </>
    )
}

export default Legend