import { Tooltip } from "@mui/joy";
import {
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";
import colorScheme from "./colorScheme";
import colorScale from "./color_scale";
import Legend from "./Legend";

import "./MapChart.css";

// this is https://github.com/deldersveld/topojson/raw/master/world-countries.json";
const geoUrl = "./world-countries.json";

function MapChart({ selectedCountry, riskData, selectedRisk, onGeoClick }) {
  return (
    <ComposableMap
      data-tip=""
      projection="geoEqualEarth"
      projectionConfig={{
        scale: 155,
        rotation: [-11, 0, 0],
      }}
      width={800}
      height={400}
      style={{ width: "100%", height: "auto" }}
    >
      {riskData.length > 0 && (
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              // The GeoJSON data is a bit odd, apply some transformations:
              // let geoId = geo.id;


              // - ID "-99" is used for Somaliland and Northern Cyprus
              //   we don't have either in riskData, but having the same is a problem.
              //   pick XS for Somaliland, as per https://stackoverflow.com/a/51730923
              if (geo.id === "-99") {
                if (geo.properties.name === "Somaliland") {
                  geo.id = "XS"
                }
              }

              // - ID "CS-KM" is used for Kosovo
              //   use XXK there, as we do in riskData._
              if (geo.id === "CS-KM") {
                geo.id = "XXK"
              }


              // calculate style props.
              let geoStyle = {
                hover: {
                  stroke: colorScheme.borderHover,
                  strokeWidth: 1,
                },
                pressed: {
                  stroke: colorScheme.borderHover,
                  strokeWidth: 1,
                }
              }
              // If the country is selected, also set stroke.
              if (selectedCountry !== undefined && selectedCountry.iso3 === geo.id) {
                geoStyle.default = {
                  stroke: colorScheme.borderActive,
                  strokeWidth: 1,
                }
              }

              // calculate the fill color. Default to some grey
              let fill = colorScheme.colorUndefined

              // Try to find the corresponding entry in riskData (by the iso3 key)
              const foundRisk = riskData.find((e) => e.iso3 === geo.id);

              // if we found a risk, check if we want to color
              if (foundRisk !== undefined) {
                // apply colorScale on the selected risk,
                // but only if no country is selected, or in case it is, if the current geo is for the currently selected country
                fill = colorScale(foundRisk[selectedRisk])
              }

              return (
                <Tooltip
                  title={geo.properties.name}
                  key={`geo-${geo.id}`}
                >
                  <Geography
                    key={`geo-${geo.id}`}
                    geography={geo}
                    fill={fill}
                    style={geoStyle}
                    onClick={() => {
                      onGeoClick(geo.id)
                    }}
                  />
                </Tooltip>
              );
            })
          }
        </Geographies>
      )}
      <Legend></Legend>
    </ComposableMap>
  );
};

export default MapChart;
