import parseData from './parse_data';
import { Async } from 'react-async';
import { CircularProgress, CssVarsProvider} from '@mui/joy';
import Main from './Main';

const fetchData = async () => {
  return await parseData(`sample_extract_countrymap.csv`)
}

const App = () => {
  return (
    <CssVarsProvider>
      <main>
        <Async promiseFn={fetchData}>
          <Async.Pending>
            <CircularProgress />
          </Async.Pending>
          <Async.Rejected>{error => <div> {error}</div>}</Async.Rejected>
          <Async.Fulfilled>{data => {
            return (<Main riskData={data}></Main>)
          }}
          </Async.Fulfilled>
        </Async>
      </main>
    </CssVarsProvider>
  )
}

export default App;
