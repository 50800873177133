import { scaleLinear } from "d3-scale";
import colorScheme from "./colorScheme";

// colorScale translates a number between 0 and 5 to a color code.
// 0 is translated to #bfbfbf (grey), for numbers between 1 and 5
// we scale from #56a3bc to #a01441 in a linear fashion.
const colorScale = (c) => {
    if (c === 0) {
        return colorScheme.colorUndefined
    }
    const scale = scaleLinear()
        .domain([1, 2, 3, 4, 5])
        .range([
            colorScheme.colorMinimum,
            colorScheme.colorMediumLow,
            colorScheme.colorMedium,
            colorScheme.colorMediumHigh,
            colorScheme.colorMaximum
        ]);
    return scale(c)
}

export default colorScale