import { Grid, Modal, ModalClose, Sheet } from '@mui/joy';
import * as React from 'react';
import CountryDetails from './CountryDetails';
import MapChart from './MapChart';
import RiskSelector from './RiskSelector';

function Main({ riskData }) {
    const [selectedRisk, setSelectedRisk] = React.useState('overall score')
    const [selectedCountry, setSelectedCountry] = React.useState(undefined)

    const handleGeoClick = (geoId) => {
        // find the geo in riskData
        setSelectedCountry(riskData.find((e) => e.iso3 === geoId));
    };

    const onRiskSelected = (newRisk) => {
        setSelectedRisk(newRisk)
    }

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <RiskSelector selectedRisk={selectedRisk} onChange={onRiskSelected} />
                </Grid>
                <Grid item xs={12}>
                    <MapChart
                        riskData={riskData}
                        selectedCountry={selectedCountry}
                        selectedRisk={selectedRisk}
                        onGeoClick={handleGeoClick}
                    />
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={selectedCountry !== undefined}
                onClose={() => setSelectedCountry(undefined)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <CountryDetails
                        country={selectedCountry}
                        style={{
                            position: "fixed",
                            left: 0,
                            bottom: 0,
                        }}
                    />
                </Sheet>
            </Modal>
        </>
    )

}

export default Main
