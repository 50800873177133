// converts a score (as number) to a text description
const scoreToText = (score) => {
    if (score < 1) {
        return "-"
    } else if (score < 1.5) {
        return "Low"
    } else if (score < 2.5) {
        return "Medium Low"
    } else if (score < 3.5) {
        return "Medium"
    } else if (score < 4.5) {
        return "Medium High"
    } else if (score <= 5) {
        return "High"
    } else {
        return "N/A"
    }
}

export default scoreToText