import { Divider, List, ListItem, ListItemContent, Typography } from "@mui/joy";
import risks from "./risks";
import scoreToText from "./scoreToText";

function CountryDetails({ country }) {
   if (country === undefined) {
      return <></>
   }
   return (
      <>
         <Typography level="h3" align="center">
            {country.country_name}
         </Typography>
         <List size="sm">
            {Object.keys(risks).map((k, idx) => {
               return <div key={`risk-${idx}`}>
                  <Divider />
                  <ListItem>
                     <ListItemContent>
                        <Typography level="h6">{risks[k].name}</Typography>
                     </ListItemContent>
                     <Typography level="h5" align="right" fontWeight="lg">
                        {country[k]}<br />
                        <Typography level="body2" fontWeight="sm">
                           {scoreToText(country[k])}
                        </Typography>
                     </Typography>
                  </ListItem>
               </div>
            })}
         </List>
      </>
   );
}

export default CountryDetails;