const colorScheme = {
    colorUndefined: 'rgb(191, 191, 191)',
    colorMinimum: 'rgb(83, 187, 78)',
    colorMediumLow: 'rgb(165, 211, 74)',
    colorMedium: 'rgb(255, 207, 14)',
    colorMediumHigh: 'rgb(255, 138, 39)',
    colorMaximum: 'rgb(219, 46, 43)',
    borderHover: 'rgb(191, 191, 191)',
    borderActive: 'rgb(51, 51, 51)',
}

export default colorScheme